import React from "react"
import { css } from "styled-components"
import { Link } from "gatsby"
import Image from "gatsby-image"
import ReadLink from "../components/read-link"
import { UiText } from "../components"
const PostPreview = ({ post }) => (
  <article
    css={css`
      border-bottom: 1px solid #ddd;
      display: flex;
      margin-top: 0;
      padding-bottom: 1rem;
      align-items: flex-start;
      max-width: 960px;
      /* :first-of-type { */
      margin-top: 1rem;
      /* } */
      h3 {
        margin-top: 0;
      }
    `}
  >
    <Link
      to={`/${post.slug}`}
      css={css`
        margin: 0rem 1rem 0 0;
        width: 100px;
        flex: 0 0 100px;
      `}
    >
      <Image
        css={css`
          * {
            margin-top: 0;
          }
        `}
        fluid={post.image.sharp.fluid}
        alt={post.title}
      />
    </Link>
    <div
      css={css`
        flex: 1;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
      `}
    >
      <h3
        css={css`
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          white-space: nowrap;
          color: var(--f-color-brandPrimary);
        `}
      >
        <Link
          css={css`
            /* color: #63c46e; */
          `}
          to={`/${post.slug}`}
        >
          {post.title}
        </Link>
      </h3>
      <UiText
        css={css`
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
        `}
        margin="0px"
        variant="content"
        as="p"
      >
        {post.excerpt}
      </UiText>
      <ReadLink
        css={css`
          /* color: #63c46e; */
        `}
        to={`/${post.slug}`}
      >
        read this post &rarr;
      </ReadLink>
    </div>
  </article>
)

export default PostPreview
